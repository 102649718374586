<template>
  <v-parallax
    class="ma-4"
    dark
    src="https://nexcel.cl/wp-content/uploads/2016/10/music-parallax-5.jpg"
    height="500"
  >
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h1 class="display-1 font-weight-thin mb-4">
          Vue Top 10 Songs API
        </h1>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
export default {
  name: 'Home',
}
</script>
